

















































































































import Vue from 'vue';

// store
import { taskDialogModule } from '@/store/viewModule/task/taskDialogModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';

// types
import { TaskItem } from '@/model/types';

// services
import { hours } from '@/service/ui/ModelService';
import { isMobile } from '@/service/StyleService';
import dayjs from 'dayjs';
import { sendEvent } from '@/service/GoogleAnalyticsService';

export interface DataType {
  creatingTask: TaskItem;
  hours: number[];
  isCreating: boolean;
}

export default Vue.extend({
  name: 'TaskCreateContainer',

  data: (): DataType => {
    return {
      creatingTask: {
        taskName: '',
        tagId: null,
        hour: 1,
      },
      hours,
      isCreating: false,
    };
  },

  computed: {
    isEditable() {
      return taskDialogModule.isEditable;
    },
    tags() {
      return tagModule.activatedTags;
    },
    createButtonDisabled(): boolean {
      if (!this.isEditable) {
        return true;
      }
      if (!this.creatingTask) {
        return true;
      }
      if (this.creatingTask.taskName === '') {
        return true;
      }
      if (this.isCreating) {
        return true;
      }
      return false;
    },
    isMobile() {
      return isMobile(window);
    },
  },

  methods: {
    async createTask() {
      this.isCreating = true;
      await taskDialogModule.createTask(this.creatingTask);
      this.creatingTask = {
        taskName: '',
        tagId: null,
        hour: 1,
      };
      this.isCreating = false;

      sendEvent('task_create', {});
      this.$intercom.trackEvent('task_create');
    },
  },
});
