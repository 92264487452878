













import Vue from 'vue';
// conmonents
import TaskpoolCreateContainer from '@/container/taskpool/TaskpoolCreateContainer.vue';
import TaskpoolTasksContainer from '@/container/taskpool/TaskpoolTasksContainer.vue';
// store
import { taskpoolViewModule } from '@/store/viewModule/taskpool/taskpoolViewModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { commonModule } from '@/store/viewModule/common/commonModule';

export interface DataType {
}

export default Vue.extend({
  name: 'TaskpoolContainer',

  data() {
    return {
    };
  },

  components: {
    TaskpoolCreateContainer,
    TaskpoolTasksContainer,
  },

  computed: {
    tags() {
      return tagModule.activatedTags;
    },
    selectedTag() {
      return taskpoolViewModule.selectedTag;
    },
  },

  methods: {
    tagName(id: string | null) {
      if (id) {
        return this.tags.filter((tag) => tag.id === id)[0].label;
      }
      return this.$tc('noTag');
    },
  },
});
