



























































































































































































































































































































import Vue from 'vue';
import dayjs from 'dayjs';
// conmonents
import { TaskItem, SuggestUser, SelectOptionItem } from '@/model/types';
import { hours } from '@/service/ui/ModelService';
// store
import { taskpoolViewModule } from '@/store/viewModule/taskpool/taskpoolViewModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { userModule } from '@/store/dataModule/user/userModule';
import { taskpoolModule } from '@/store/dataModule/report/taskpoolModule';

// models
import { DailyReportResponse, TeamResponse, UserResponse } from 'remonade-api/lib';

// services
import { isMobile } from '@/service/StyleService';

export interface DataType {
  hours: number[];
  anyone: UserResponse;
}

export default Vue.extend({
  name: 'TaskpoolTasksContainer',

  data() {
    return {
      hours,
      anyone: {
        userId: 'ANYONE',
        username: 'Anyone',
      } as UserResponse,
    };
  },

  components: {
  },

  computed: {
    user(): UserResponse | null {
      return taskpoolViewModule.user;
    },
    teamUsers(): UserResponse[] {
      return taskpoolViewModule.teamUsers;
    },
    tagList(): SelectOptionItem[] {
      const tags = this.tags.concat();
      const noTag: SelectOptionItem = {
        id: '',
        value: null,
        label: this.$tc('noTag'),
        color: null,
      };
      tags.unshift(noTag);
      return tags;
    },
    activeTags(): SelectOptionItem[] {
      return this.tagList.filter((tag) => this.tagPoolTasks(tag.id).length > 0);
    },
    tags() {
      return tagModule.activatedTags;
    },
    selectedTag(): string {
      return taskpoolViewModule.selectedTag;
    },
    optionUsers(): UserResponse[] {
      if (!this.user) { return []; }
      const users = this.teamUsers.concat();
      users.unshift(this.anyone);
      return users;
    },
    poolTasks() {
      return taskpoolViewModule.poolTasks;
    },
  },

  methods: {
    tagPoolTasks(tagId: string) {
      if (!tagId) {
        return this.poolTasks.filter((t) => !t.tag);
      }
      return this.poolTasks.filter((t) => t.tag === tagId);
    },
    tagPoolTasksMine(tagId: string) {
      const tasks = this.tagPoolTasks(tagId).filter((task) => {
        if (this.user && task.suggest) {
          return this.suggestedUsers(task.suggest).findIndex((u) => {
            if (u) {
              return u.userId === this.user!.userId;
            }
          }) >= 0;
        }
      });
      return tasks;
    },
    suggestedUsers(suggest: string) {
      if (suggest) {
        const ids = suggest.split(',');
        const users = ids.map((id) => {
          const find = this.optionUsers.find((u) => u.userId === id);
          return find || null;
        }) as UserResponse[];
        return users;
      }
      return [];
    },
    suggestMe(tasks: DailyReportResponse[]) {
      let count: number = 0;
      for (const task of tasks) {
        let flag: boolean = false;
        if (task.suggest && this.user) {
          const users = this.suggestedUsers(task.suggest);
          if (users) {
            for (const u of users) {
              if (u) {
                if (u.userId === this.user!.userId) {
                  flag = true;
                }
              }
            }
          }
        }
        if (flag) {
          count += 1;
        }
      }
      return count;
    },
    suggestAnyone(tasks: DailyReportResponse[]) {
      let count: number = 0;
      for (const task of tasks) {
        let flag: boolean = false;
        if (task.suggest && this.user) {
          const users = this.suggestedUsers(task.suggest);
          if (users) {
            for (const u of users) {
              if (u) {
                if (u.userId === 'ANYONE') {
                  flag = true;
                }
              }
            }
          }
        }
        if (flag) {
          count += 1;
        }
      }
      return count;
    },
    suggestOthers(tasks: DailyReportResponse[]) {
      let count: number = 0;
      for (const task of tasks) {
        let flag: boolean = true;
        if (task.suggest && this.user) {
          const users = this.suggestedUsers(task.suggest);
          if (users) {
            for (const u of users) {
              if (u) {
                if (u.userId === this.user!.userId || u.userId === 'ANYONE') {
                  flag = false;
                }
              }
            }
          }
        }
        if (flag) {
          count += 1;
        }
      }
      return count;
    },
    async updatePoolTaskName(task: DailyReportResponse, taskName: string) {
      const newTask = {...task};
      newTask.task = taskName;
      await taskpoolModule.updateTask(newTask);
    },
    async updatePoolTaskTag(task: DailyReportResponse, tagId: string) {
      const newTask = {...task};
      newTask.tag = tagId;
      await taskpoolModule.updateTask(newTask);
    },
    async updatePoolTaskHour(task: DailyReportResponse, hour: number) {
      const newTask = {...task};
      newTask.hour = hour;
      await taskpoolModule.updateTask(newTask);
    },
    async updatePoolTaskSuggest(task: DailyReportResponse, users: UserResponse[]) {
      const newTask = {...task};
      const ids = users.filter((user) => user !== null).map((user) => user.userId);
      if (ids) {
        newTask.suggest = ids.join(',');
      } else {
        newTask.suggest = ' ';
      }
      await taskpoolModule.updateTask(newTask);
    },
    async deletePoolTaskSuggest(task: DailyReportResponse, user: UserResponse) {
      const newTask = {...task};
      const users = this.suggestedUsers(newTask.suggest!).filter((u) => u !== user);
      const ids = users.filter((u) => u !== null).map((u) => u.userId);
      if (ids) {
        newTask.suggest = ids.join(',');
      } else {
        newTask.suggest = ' ';
      }
      await taskpoolModule.updateTask(newTask);
    },
    async moveToToday(task: DailyReportResponse) {
      if (this.user) {
        const newTask = {...task};
        newTask.date = dayjs().format('YYYY-MM-DD');
        newTask.type = 'task';
        newTask.userId = this.user.userId;
        newTask.sortKey = `${this.user.teamId}#${this.user.userId}`;
        await taskpoolModule.moveToToday(newTask);
      }
    },
    async deleteTask(task: DailyReportResponse) {
      await taskpoolModule.deleteTask(task);
    },
  },

  async mounted() {
  },
});
