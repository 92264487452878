






































import Vue from 'vue';

// store
import { taskDialogModule } from '@/store/viewModule/task/taskDialogModule';

// types
import { TaskItem } from '@/model/types';

// services
import { hours } from '@/service/ui/ModelService';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import dayjs from 'dayjs';
import { sendEvent } from '@/service/GoogleAnalyticsService';

export interface DataType {
  hours: number[];
  date: string;
}

export default Vue.extend({
  name: 'TaskCopyContainer',

  data: (): DataType => {
    return {
      hours,
      date: dayjs().format('YYYY-MM-DD'),
    };
  },

  watch: {
    copyingTask: {
      deep: true,
      handler(value: TaskItem) {
        if (value && value.date) {
          this.date = value.date;
        }
      },
    },
  },

  computed: {
    isEditable() {
      return taskDialogModule.isEditable;
    },
    tags() {
      return tagModule.activatedTags;
    },
    isDialogOpen() {
      if (taskDialogModule.copyingTask) {
        return true;
      }
      return false;
    },
    copyingTask() {
      return taskDialogModule.copyingTask;
    },
  },

  methods: {
    close() {
      taskDialogModule.setCopyingTask(null);
    },
    save() {
      taskDialogModule.copyTask(this.date);

      sendEvent('task_copy', {});
      this.$intercom.trackEvent('task_copy');
    },
  },
});
