










































































































































































































































































































































import Vue from 'vue';

// store
import { GoogleCalendar, taskDialogModule } from '@/store/viewModule/task/taskDialogModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';

// types
import { TaskItem } from '@/model/types';

// components
import draggable from 'vuedraggable';

// services
import { hours } from '@/service/ui/ModelService';
import dayjs from 'dayjs';
import { isMobile } from '@/service/StyleService';
import { DailyReportResponse, GoogleCalendarResponse, UserResponse } from 'remonade-api/lib';
import { calendarModule } from '@/store/dataModule/calendar/calendarModule';
import { sendEvent } from '@/service/GoogleAnalyticsService';

export interface DataType {
  hours: number[];
  creatingSubtasks: TaskItem[];
  virtualTasks: TaskItem[];
}

export default Vue.extend({
  name: 'TodayTasksContainer',

  components: {
    draggable,
  },

  data: (): DataType => {
    return {
      hours,
      creatingSubtasks: [],
      virtualTasks: [],
    };
  },

  watch: {
    tasks: {
      immediate: true,
      deep: true,
      handler(tasks) {
        this.virtualTasks = tasks;
      },
    },
  },

  computed: {
    user(): UserResponse | null {
      return taskDialogModule.user;
    },
    tasks() {
      return taskDialogModule.parentTasks.sort((t1, t2) => (t1.displayOrder || 0) - (t2.displayOrder || 0));
    },
    totalTaskHour() {
      return taskDialogModule.parentTasks.reduce((total, task) => {
        if (this.hasSubtasks(task.taskId)) {
          const subTotal = this.calcSubtaskHours(task.taskId);
          return total + subTotal;
        }
        return task.hour + total;
      }, 0);
    },
    tags() {
      return tagModule.activatedTags;
    },
    isEditable() {
      return taskDialogModule.isEditable;
    },
    isMobile() {
      return isMobile(window);
    },
    isGoogleUser(): boolean {
      return taskDialogModule.isGoogleUser;
    },
    connectedCalendars(): GoogleCalendar[] {
      return taskDialogModule.connectedCalendars;
    },
  },

  methods: {
    findSubtasks(taskId: string) {
      return taskDialogModule.tasks.filter((task) => task.parentId === taskId);
    },
    hasSubtasks(taskId: string) {
      return this.findSubtasks(taskId).length > 0;
    },
    calcSubtaskHours(taskId: string) {
      const subtasks = this.findSubtasks(taskId);
      return subtasks.reduce((value, task) => {
        return value + (task.hour || 0);
      }, 0);
    },
    findCreatingSubtask(taskId: string) {
      return this.creatingSubtasks.find((taskItem) => taskItem.parentId === taskId) || null;
    },
    openSubtask(taskId: string) {
      const subtasks = [...this.creatingSubtasks];
      subtasks.push({
        taskName: '',
        hour: 1,
        parentId: taskId,
      });
      this.creatingSubtasks = subtasks;
    },
    closeSubtask(taskId: string) {
      this.creatingSubtasks = this.creatingSubtasks.filter((taskItem) => taskItem.parentId !== taskId);
    },
    async createSubtask(taskId: string) {
      const subtask = this.creatingSubtasks.find((taskItem) => taskItem.parentId === taskId);
      if (subtask) {
        await taskDialogModule.createSubtask(subtask);
        this.closeSubtask(taskId);

        sendEvent('subtask_create', {});
      }
    },
    async updateTaskDone(taskItem: TaskItem) {
      if (this.isEditable && taskItem.taskId) {
        const isDone = taskItem.isDone ? !taskItem.isDone : true;
        await taskDialogModule.updateTaskDone({ taskId: taskItem.taskId, isDone });

        sendEvent('task_done_dialog', {});
      }
    },
    async updateTaskName(task: TaskItem, taskName: string) {
      const newTask = {...task};
      newTask.taskName = taskName;
      await taskDialogModule.updateTask(newTask);

      sendEvent('task_update_name', {});
    },
    async updateTaskTag(task: TaskItem, tagId: string) {
      const newTask = {...task};
      newTask.tagId = tagId;
      await taskDialogModule.updateTask(newTask);

      sendEvent('task_update_tag', {});
    },
    async updateTaskHour(task: TaskItem, hour: number) {
      const newTask = {...task};
      newTask.hour = hour;
      await taskDialogModule.updateTask(newTask);

      sendEvent('task_update_hour', {});
    },
    async updateTask(taskItem: TaskItem) {
      await taskDialogModule.updateTask(taskItem);

      sendEvent('task_update', {});
    },
    async onTasksReorder(e) {
      const parentTasks = [...this.tasks];
      const oldIndex: number = e.oldIndex;
      const newIndex: number = e.newIndex;
      const movedTask = parentTasks[oldIndex];

      if (movedTask && movedTask.displayOrder && movedTask.displayOrder <= 0) { return; }
      if (newIndex + 1 >= parentTasks.length) {
        // moved to the last
        const newDisplayOrder = dayjs().unix();
        movedTask.displayOrder = newDisplayOrder;
        this.updateTask(movedTask);
      } else if (newIndex === 0) {
        // moved to the top
        const topDisplayOrder = parentTasks[0].displayOrder || 0;
        const newDisplayOrder = topDisplayOrder / 2;
        movedTask.displayOrder = newDisplayOrder;
        this.updateTask(movedTask);
      } else {
        // moved to the middle
        const rests = parentTasks.filter((t) => t.taskId !== movedTask.taskId);
        const preDisplayOrder = rests[newIndex - 1].displayOrder;
        const postDisplayOrder = rests[newIndex].displayOrder;
        if (preDisplayOrder && postDisplayOrder) {
          const newDisplayOrder = (preDisplayOrder + postDisplayOrder) / 2;
          movedTask.displayOrder = newDisplayOrder;
          this.updateTask(movedTask);
        }
      }

      sendEvent('task_reorder', {});
    },
    async deleteTask(taskItem: TaskItem) {
      if (taskItem.taskId) {
        await taskDialogModule.deleteTask(taskItem.taskId);
        sendEvent('task_delete', {});
      }
    },
    setCopyingTask(taskItem: TaskItem) {
      taskDialogModule.setCopyingTask(taskItem);
    },
    setMovingTask(taskItem: TaskItem) {
      taskDialogModule.setMovingTask(taskItem);
    },
    updateCalendarTaskTag(task, tag) {
      taskDialogModule.setConnectedCalendarTaskTag({ updatedTask: task, tag });
    },
    async moveToToday(task: TaskItem) {
      await taskDialogModule.createTask(task);
      taskDialogModule.removeConnectedCalendarTask(task);
    },
    async authorizeGoogleCalendar() {
      await taskDialogModule.authorizeGoogleCalendar();
      await taskDialogModule.updateUserCalendarEnabled();
    },
  },
});
