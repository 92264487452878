var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selectedTag === 'ALL')?_c('div',[_c('v-list',_vm._l((_vm.activeTags),function(tag){return _c('v-list-group',{key:tag.id,staticClass:"mb-2",staticStyle:{"border":"solid 1px #f0f0f0"},attrs:{"color":tag.color,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{staticClass:"tag-header"},[_c('div',{staticClass:"d-flex justify-start"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(tag.label)}}),(_vm.suggestAnyone(_vm.tagPoolTasks(tag.id)) > 0)?_c('v-chip',{staticClass:"mr-1",attrs:{"outlined":"","small":"","color":"danger"}},[_vm._v(_vm._s(_vm.suggestAnyone(_vm.tagPoolTasks(tag.id))))]):_vm._e(),(_vm.suggestMe(_vm.tagPoolTasks(tag.id)) > 0)?_c('v-chip',{staticClass:"mr-1",attrs:{"outlined":"","small":"","color":"primary"}},[_vm._v(_vm._s(_vm.suggestMe(_vm.tagPoolTasks(tag.id))))]):_vm._e(),(_vm.suggestOthers(_vm.tagPoolTasks(tag.id)) > 0)?_c('v-chip',{attrs:{"small":"","outlined":"","color":"grey"}},[_vm._v(_vm._s(_vm.suggestOthers(_vm.tagPoolTasks(tag.id))))]):_vm._e()],1)])]},proxy:true}],null,true),model:{value:(tag.active),callback:function ($$v) {_vm.$set(tag, "active", $$v)},expression:"tag.active"}},_vm._l((_vm.tagPoolTasks(tag.id)),function(task){return _c('v-list-item',{key:task.id},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveToToday(task)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-playlist-plus")])],1)]}}],null,true)},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('addToTask')))])])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-2"},[_c('v-row',[_c('v-col',{staticClass:"py-0 mb-2",attrs:{"cols":"12","sm":"12","md":"7"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","single-line":"","outlined":"","value":task.task},on:{"change":function($event){return _vm.updatePoolTaskName(task, $event)}}})],1),_c('v-col',{staticClass:"py-0 mb-2",attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"value":task.tag,"items":_vm.tags,"item-text":"label","item-value":"id","outlined":"","hide-details":"","dense":"","single-line":""},on:{"change":function($event){return _vm.updatePoolTaskTag(task, $event)}}})],1),_c('v-col',{staticClass:"py-0 mb-2",attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-select',{attrs:{"value":task.hour,"items":_vm.hours,"outlined":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.updatePoolTaskHour(task, $event)}}})],1)],1)],1),_c('v-list-item-subtitle',{staticClass:"pa-1"},[_c('v-select',{attrs:{"dense":"","chips":"","deletable-chips":"","multiple":"","return-object":"","solo":"","append-icon":'mdi-plus',"items":_vm.optionUsers,"item-text":"username","item-value":"user_id","value":_vm.suggestedUsers(task.suggest),"placeholder":_vm.$t('addSuggest')},on:{"input":function($event){return _vm.updatePoolTaskSuggest(task, $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.userId === 'ANYONE')?_c('v-chip',{attrs:{"color":"danger","outlined":"","close":""},on:{"click:close":function($event){return _vm.deletePoolTaskSuggest(task, item)}}},[_vm._v(_vm._s(item.username))]):(item.userId === _vm.user.userId)?_c('v-chip',{attrs:{"color":"primary","outlined":"","close":""},on:{"click:close":function($event){return _vm.deletePoolTaskSuggest(task, item)}}},[_vm._v(_vm._s(item.username))]):_c('v-chip',{attrs:{"color":"grey","outlined":"","close":""},on:{"click:close":function($event){return _vm.deletePoolTaskSuggest(task, item)}}},[_vm._v(_vm._s(item.username))])]}}],null,true)})],1)],1),_c('v-list-item-avatar',{staticStyle:{"align-self":"flex-start"}},[_c('v-btn',{attrs:{"color":"danger","icon":""},on:{"click":function($event){return _vm.deleteTask(task)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)}),1)}),1)],1):_vm._e(),(_vm.selectedTag === 'MINE')?_c('div',[_c('v-list',_vm._l((_vm.activeTags),function(tag){return _c('v-list-group',{key:tag.id,staticClass:"mb-2",staticStyle:{"border":"solid 1px #f0f0f0"},attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{staticClass:"tag-header"},[_c('div',{staticClass:"d-flex justify-start"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(tag.label)}}),(_vm.suggestMe(_vm.tagPoolTasksMine(tag.id)) > 0)?_c('v-chip',{attrs:{"small":"","outlined":"","color":"primary"}},[_vm._v(_vm._s(_vm.suggestMe(_vm.tagPoolTasksMine(tag.id))))]):_vm._e()],1)])]},proxy:true}],null,true),model:{value:(tag.active),callback:function ($$v) {_vm.$set(tag, "active", $$v)},expression:"tag.active"}},_vm._l((_vm.tagPoolTasksMine(tag.id)),function(task){return _c('v-list-item',{key:task.id},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveToToday(task)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-playlist-plus")])],1)]}}],null,true)},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('addToTask')))])])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-2"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"7"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","single-line":"","outlined":"","value":task.task},on:{"input":function($event){return _vm.updatePoolTaskName(task, $event)}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"2"}},[_c('v-autocomplete',{attrs:{"value":task.tag,"items":_vm.tags,"item-text":"label","item-value":"id","outlined":"","hide-details":"","dense":"","single-line":""},on:{"input":function($event){return _vm.updatePoolTaskTag(task, $event)}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"2"}},[_c('v-select',{attrs:{"value":task.hour,"items":_vm.hours,"outlined":"","hide-details":"","dense":""},on:{"input":function($event){return _vm.updatePoolTaskHour(task, $event)}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteTask(task)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-list-item-subtitle',[_c('v-select',{attrs:{"dense":"","chips":"","deletable-chips":"","multiple":"","return-object":"","append-icon":'mdi-plus',"items":_vm.optionUsers,"item-text":"username","item-value":"user_id","value":_vm.suggestedUsers(task.suggest),"placeholder":_vm.$t('addSuggest')},on:{"input":function($event){return _vm.updatePoolTaskSuggest(task, $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.userId === 'ANYONE')?_c('v-chip',{attrs:{"outlined":"","color":"danger","close":""},on:{"click:close":function($event){return _vm.deletePoolTaskSuggest(task, item)}}},[_vm._v(_vm._s(item.username))]):(item.userId === _vm.user.userId)?_c('v-chip',{attrs:{"outlined":"","color":"primary","close":""},on:{"click:close":function($event){return _vm.deletePoolTaskSuggest(task, item)}}},[_vm._v(_vm._s(item.username))]):_c('v-chip',{attrs:{"color":"grey","outlined":"","close":""},on:{"click:close":function($event){return _vm.deletePoolTaskSuggest(task, item)}}},[_vm._v(_vm._s(item.username))])]}}],null,true)})],1)],1)],1)}),1)}),1)],1):_c('div',[_c('v-list',_vm._l((_vm.tagPoolTasks(_vm.selectedTag)),function(task){return _c('v-list-item',{key:task.id},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveToToday(task)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-playlist-plus")])],1)]}}],null,true)},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('addToTask')))])])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-2"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"7"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","single-line":"","outlined":"","value":task.task},on:{"input":function($event){return _vm.updatePoolTaskName(task, $event)}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"2"}},[_c('v-autocomplete',{attrs:{"value":task.tag,"items":_vm.tags,"item-text":"label","item-value":"id","outlined":"","hide-details":"","dense":"","single-line":""},on:{"input":function($event){return _vm.updatePoolTaskTag(task, $event)}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"2"}},[_c('v-select',{attrs:{"value":task.hour,"items":_vm.hours,"outlined":"","hide-details":"","dense":""},on:{"input":function($event){return _vm.updatePoolTaskHour(task, $event)}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteTask(task)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-list-item-subtitle',[_c('v-select',{attrs:{"dense":"","chips":"","deletable-chips":"","multiple":"","outlined":"","return-object":"","append-icon":'mdi-plus',"items":_vm.optionUsers,"item-text":"username","item-value":"user_id","value":_vm.suggestedUsers(task.suggest),"placeholder":_vm.$t('addSuggest')},on:{"input":function($event){return _vm.updatePoolTaskSuggest(task, $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.userId === 'ANYONE')?_c('v-chip',{attrs:{"outlined":"","color":"danger","close":""},on:{"click:close":function($event){return _vm.deletePoolTaskSuggest(task, item)}}},[_vm._v(_vm._s(item.username))]):(item.userId === _vm.user.userId)?_c('v-chip',{attrs:{"outlined":"","color":"primary","close":""},on:{"click:close":function($event){return _vm.deletePoolTaskSuggest(task, item)}}},[_vm._v(_vm._s(item.username))]):_c('v-chip',{attrs:{"color":"grey","outlined":"","close":""},on:{"click:close":function($event){return _vm.deletePoolTaskSuggest(task, item)}}},[_vm._v(_vm._s(item.username))])]}}],null,true)})],1)],1)],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }