







































































import Vue from 'vue';
import dayjs from 'dayjs';
// conmonents
import { TaskItem, SuggestUser, SelectOptionItem } from '@/model/types';
import { hours } from '@/service/ui/ModelService';
// store
import { taskpoolViewModule } from '@/store/viewModule/taskpool/taskpoolViewModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { userModule } from '@/store/dataModule/user/userModule';
import { taskpoolModule } from '@/store/dataModule/report/taskpoolModule';
import { UserResponse } from 'remonade-api/lib';

import { isMobile } from '@/service/StyleService';

export interface DataType {
  creatingTask: TaskItem;
  hours: number[];
  creatingSuggestUsers: UserResponse[];
  anyone: UserResponse;
}

export default Vue.extend({
  name: 'TaskpoolCreateContainer',

  data() {
    return {
      creatingTask: {
        taskName: '',
        tagId: '',
        hour: 1,
        color: null,
      },
      hours,
      creatingSuggestUsers: [] as UserResponse[],
      anyone: {
        userId: 'ANYONE',
        username: 'Anyone',
      } as UserResponse,
    };
  },

  components: {
  },

  computed: {
    user(): UserResponse | null {
      return taskpoolViewModule.user;
    },
    teamUsers(): UserResponse[] {
      return taskpoolViewModule.teamUsers;
    },
    tagList(): SelectOptionItem[] {
      const tags = this.tags.concat();
      const noTag: SelectOptionItem = {
        id: '',
        value: null,
        label: this.$tc('noTag'),
        color: null,
      };
      tags.unshift(noTag);
      return tags;
    },
    tags() {
      return tagModule.activatedTags;
    },
    selectedTag(): string {
      return taskpoolViewModule.selectedTag;
    },
    createButtonDisabled(): boolean {
      if (!this.creatingTask) {
        return true;
      }
      if (this.creatingTask.taskName === '') {
        return true;
      }
      return false;
    },
    optionUsers(): UserResponse[] {
      if (!this.user) { return []; }
      const users = this.teamUsers.concat();
      users.unshift(this.anyone);
      return users;
    },
    isMobile() {
      return isMobile(window);
    },
  },

  methods: {
    async createPoolTask() {
      const suggest = this.creatingSuggestUsers.map((u) => u.userId).join(',');
      await taskpoolViewModule.createPoolTask({ taskItem: this.creatingTask, suggest });
      this.creatingTask = {
        taskName: '',
        tagId: '',
        hour: 1,
        color: null,
      };
      this.creatingSuggestUsers = [];
    },
    deleteSuggestedUser(user: UserResponse) {
      this.creatingSuggestUsers = this.creatingSuggestUsers.filter((u) => u.userId !== user.userId);
    },
  },

  watch: {
    selectedTag() {
      if (this.selectedTag === 'ALL') {
        this.creatingTask　=  {
          taskName: '',
          tagId: '',
          hour: 1,
          color: null,
        },
        this.creatingSuggestUsers = [];
      } else if (this.selectedTag === 'MINE' && this.user) {
        this.creatingTask　=  {
          taskName: '',
          tagId: '',
          hour: 1,
          color: null,
        },
        this.creatingSuggestUsers = [this.user];
      } else {
        this.creatingTask　=  {
          taskName: '',
          tagId: this.selectedTag,
          hour: 1,
          color: null,
        },
        this.creatingSuggestUsers = [];
      }
    },
  },

  async mounted() {
  },
});
